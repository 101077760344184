<template>
  <div>
    <div class="app-header">
      <div class="container">
        <div class="row no-gutters">
          <div class="app-header-title">
            <p style="margin: 10px">K-Link</p>
          </div>
        </div>
      </div>
    </div>
    <div class="authenticate__wrapper" id="login">
      <div class="authenticate__content">
        <form class="card" @submit.prevent="onSubmit" method="post">
          <div class="card-body border">
            <h4 class="mb-3 authenticate__title text-center">K-Linkログイン</h4>
            <div class="form-group mb-4">
              <label class="form-label font-1">ユーザーＩＤ</label>
              <input
                class="form-control"
                type="text"
                name="username"
                id="username"
                ref="username"
              />
              <small class="form-text"> </small>
              <small class="form-error"> </small>
            </div>
            <div class="form-group mb-4">
              <label class="form-label font-1">パスワード</label>
              <input
                class="form-control"
                type="password"
                name="password"
                ref="password"
              />
              <small class="form-text"> </small>
              <small class="form-error"> </small>
            </div>
            <div class="form-group mb-4">
              <p style="color: red; font-weight: bold; font-size: 9.5pt">
                {{ message == "" ? "&nbsp;" : message }}
              </p>
            </div>
            <div class="form-group mb-4">
              <button type="submit" class="btn btn-primary w-100 btn-login">
                ログイン
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      vue: this,
      message: "",
      OPENVIDU_SERVER_URL: process.env.VUE_APP_OPENVIDU_SERVER_URL,
      OPENVIDU_SERVER_SECRET: process.env.VUE_APP_OPENVIDU_SERVER_SECRET,
      flgExist: false,
      flgCount: false,
      dataUser :[],
      flgCheckAuthentication : false,
    };
  },
  mounted() {
    $("body").css({
      background:
        "linear-gradient(45deg, rgba(101, 31, 87, 1) 0%,rgba(225, 113, 87, 1) 48%,rgba(249, 248, 113, 1) 100%)",
    });
    $("input").css({
      background:
        "white",
    });
    if (!this.$cookies.isKey("token")) {
      this.$router.push("/login");
    } else {
      var token = this.$cookies.get("token");
      this.checkAuthen(token);
    }
    if (this.$route.query.message != undefined) {
      if (this.$route.query.message != "") {
        this.message = this.$route.query.message;
      }
    }
  },
  methods: {
    onSubmit() {
      if(!this.$cookies.isKey("token")){
      if (this.$refs.username.value == "") {
        this.message = "ユーザーＩＤを入力してください";
      } else {
        if (this.$refs.password.value == "") {
          this.message = "パスワードを入力してください";
        } else {
          const formData = {
            username: this.$refs.username.value,
            password: this.$refs.password.value,
          };
          this.login(formData);
        }
      }
      }else{
        this.$alert("ログインセッションがブラウザに表示されました");
      }
    },
    logoutSesion(){
      var token = this.$cookies.get("token");
      // if(token!= null || token != ''){
      //   this.delAuthen(token);
      // }
      this.$cookies.remove("token");
      this.$cookies.remove("usertoken");
    },
    delAuthen(tokenid) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/delAuthen`, {
          token: tokenid,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data == "success") {
              this.flg = true;
            }
          }
        })

        .catch((error) => console.log(error));
    },
    login(authData) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/login`, {
          username: authData.username,
          password: authData.password,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data != "fail") {
              // this.$session.start();
              // this.$session.set("token", response.data);
              if(response.data == 'This account has been logged in somewhere else'){
                this.message = 'このアカウントは別の場所にログインしています';
                this.logoutSesion();
              }else{
                this.$cookies.set("token", response.data, 60 * 60 * 24 );
                this.checkAuthen(response.data);
                // .then(async (data1) => {
                //   if (data1 != null) {
                //     if (data1.length != 0) {
                //       if (!data1.active) {
                //         this.message = "ユーザーＩＤがアクティブではありません";
                //         this.logoutSesion();
                //         // this.$cookies.remove("token");
                //         // this.$cookies.remove("usertoken");
                //       } else {
                //         this.dataUser = data1;
                //         this.$cookies.set("usertoken", data1, 60 * 60);
                //         if (data1.roleId == 1) {
                //           this.$router.push("/administrator");
                //         } else {
                //           debugger
                //           this.checkLoginSession(data1).then(async (data2) => {
                //             console.log('12355');
                //             console.log(data2.connections);
                //             this.message = "123567";
                //             this.checkSessionExist(data1.operator);
                //           if(this.flgCount == true){
                //             this.message = "現在、部屋には10人がいます。 部屋に入ることはできません。";
                //             this.logoutSesion();
                //           }else{
                //             if(this.flgExist == true){
                //               this.$confirm("部屋はすでに存在します。 部屋に入りますか？").then(() => {
                //                 this.$router.push("/");
                //               });
                //               this.logoutSesion();
                //             }else{
                //               this.$router.push("/");
                //             }
                //           }
                //           });
                          
                //         }
                //       }
                //     }
                //     // if (data1.roleId == 1) {
                //     //   this.$router.push("/dashboard");
                //     // } else {
                //     //   this.$router.push("/");
                //     // }
                //   } else {
                //     this.$router.push("/login");
                //     this.logoutSesion();
                //     // this.$cookies.remove("token");
                //     // this.$cookies.remove("usertoken");
                //   }
                // });
                // if (response.data.roleId == 1) {
                //   console.log(response.data.roleId);
                //   this.$router.push("/dashboard");
                // } else {
                // }
            }
            } else {
              this.message = "ユーザーＩＤ又はパスワードが正しくありません";
            }
          }
        })

        .catch((error) => console.log(error));
    },
    checkAuthen(tokenclient) {
        axios
          .post(`${this.OPENVIDU_SERVER_URL}/vti/authentication`, {
            token: tokenclient,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                var data1 = response.data;
                if (data1 != null) {
                    if (data1.length != 0) {
                      if (!data1.active) {
                        this.message = "ユーザーＩＤがアクティブではありません";
                        this.logoutSesion();
                        // this.$cookies.remove("token");
                        // this.$cookies.remove("usertoken");
                      } else {
                        this.dataUser = data1;
                        this.$cookies.set("usertoken", data1, 60 * 60 * 24 );
                        if (data1.roleId == 1) {
                          this.$router.push("/administrator");
                        } else {
                          this.checkLoginSession(data1);      
                        }
                      }
                    }
                    // if (data1.roleId == 1) {
                    //   this.$router.push("/dashboard");
                    // } else {
                    //   this.$router.push("/");
                    // }
                  } else {
                    this.$router.push("/login");
                    this.logoutSesion();
                    // this.$cookies.remove("token");
                    // this.$cookies.remove("usertoken");
                  }
              }
            }
          })
          .catch((error) => {
            console.log(error)
            // this.$router.go(0);
          });
    },
    checkLoginSession(data1) {
  
        axios
          .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${data1.operator}`, {
            auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                this.flgExist = true;
                if(JSON.parse(response.data.connections.numberOfElements) == 10){
                  this.flgCount = true;
                }else{
                  this.flgCount = false;
                  this.message = '';
                }
                var connection = response.data.connections;
                for (var i = 0; i < connection.content.length; i++) {
                  var content = connection.content[i];
                  // console.log(content.platform.includes('Android'));
                  console.log(JSON.parse(content.clientData).user)
                  if(JSON.parse(content.clientData).user == data1.username){
                    this.flgCheckAuthentication = true;
                    console.log(JSON.parse(content.clientData).user)
                    break;
                  }
                  this.flgCheckAuthentication = false;
                }
                if(this.flgCheckAuthentication){
                  this.message = "このアカウントは別の場所にログインしています";
                  this.logoutSesion();
                }else{
                  if(this.flgCount == true){
                    this.message = "このルームは許可されている最大参加人数に達しました。一部の参加者が退席すると、このルームに参加できる場合があります";
                    this.logoutSesion();
                  }else{
                    if(this.flgExist == true){
                      this.$confirm("部屋はすでに存在します。 部屋に入りますか？").then(() => {
                        this.$router.push("/");
                        this.$router.push({
                          path: "/",
                          query: { triggerLogin: true },
                        });
                      });
                      // this.logoutSesion();
                    }else{
                      this.$router.push("/");
                    }
                  }
                } 
              }
            }
          })
          .catch((error) => {
            this.flgExist = false;
            console.log(error)
            this.$router.push("/");
            // this.$router.go(0);
          });
    },
    async checkSessionExist(sessionId) {
        await axios
          .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}`, {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                this.flgExist = true;
                if(JSON.parse(response.data.connections.numberOfElements) == 1){
                  this.flgCount = true;
                }
              }
            }
          })
          .catch((error) => {
            this.flgExist = false;
          });
    },
  },
};
</script>

<style lang="scss" >
@import "./src/assets/scss/abstracts/main";
@import "./src/assets/scss/globals/modal";
@import "../assets/css/material-dashboard.css";
@import "../assets/css/css.css";
@import "../assets/demo/demo.css";
// input {
//   background-color: white !important;
// }
#login {
  background-color: white;
}
.border {
  border: 1px solid;
  border-radius: 3px;
}
.btn-login {
  font-size: 15px !important;
  font-weight: bolder !important;
}
.btn-login:hover {
  background-color: #ee7e04c2 !important;
  color: black !important;
}
.font-1 {
  color: black;
}
.app-header {
  padding: 10px;
  background: map-get($color, gardient);
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.app-header-title {
  p {
    text-transform: uppercase;
    font-size: 2.4rem;
    color: map-get($color, white);
  }
}
.app-header-close {
  padding: 8px;
  background-color: darken(map-get($color, white), 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.3s ease;
  p {
    margin-right: 10px;
    transition: all 0.3s ease;
  }
  span {
    font-size: 20px;
    transition: all 0.3s ease;
  }
  @media (min-width: 1025px) {
    &:hover {
      background-color: darken(map-get($color, main), 40%);
      p,
      span {
        color: map-get($color, white);
      }
    }
  }
}
.authenticate__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  // background-color: map-get($color, main);
}
.authenticate__content {
  position: relative;
  padding: 15px;
  z-index: 5;
  width: 100%;
  max-width: 450px;
  margin-bottom: 150px;
}
.authenticate__content {
  .card {
    border-radius: 5px;
    -webkit-box-shadow: var(--shadow-1);
    box-shadow: var(--shadow-1);
  }
  .card-body {
    padding: 25px;
    background-color: antiquewhite;
  }
  .form-group {
    display: block;
  }
  .form-control {
    height: 40px;
    font-size: 1.5rem;
    border: 1px solid #e9ecef;
  }
}
.authenticate__title {
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
  color: map-get($color, main);
}
.btn-primary {
  font-size: 15px;
  font-weight: bolder;
  color: #fff;
  background-color: #ee7e04;
  border-color: #ee7e04;
  box-shadow: 0 2px 2px 0 rgb(156 39 176 / 14%),
    0 3px 1px -2px rgb(156 39 176 / 20%), 0 1px 5px 0 rgb(156 39 176 / 12%);
}
</style>